var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',{staticClass:"match-height"},[_c('b-col',{attrs:{"cols":"12"}},[_c('b-card',{attrs:{"title":this.$route.meta.title}},[_c('b-alert',{directives:[{name:"height-fade",rawName:"v-height-fade"},{name:"id",rawName:"v-id",value:(_vm.showDismissibleAlert == true),expression:"showDismissibleAlert == true"}],staticClass:"mb-2",attrs:{"dismissible":"","fade":"","variant":"danger"},model:{value:(_vm.showDismissibleAlert),callback:function ($$v) {_vm.showDismissibleAlert=$$v},expression:"showDismissibleAlert"}},[_c('div',{staticClass:"alert-body"},[_c('span',[_vm._v(_vm._s(_vm.messages))])])]),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.save)}}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Nama","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"No NIK KTP","label-for":"mc-name"}},[_c('b-form-input',{attrs:{"id":"mc-name","placeholder":"Masukkan No NIK KTP"},model:{value:(_vm.model.name),callback:function ($$v) {_vm.$set(_vm.model, "name", $$v)},expression:"model.name"}}),_c('p',{staticClass:"fs-6 text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Nama","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Nama","label-for":"mc-name"}},[_c('b-form-input',{attrs:{"id":"mc-name","placeholder":"Masukkan Nama"},model:{value:(_vm.model.name),callback:function ($$v) {_vm.$set(_vm.model, "name", $$v)},expression:"model.name"}}),_c('p',{staticClass:"fs-6 text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Nama","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Tempat Lahir","label-for":"mc-name"}},[_c('b-form-input',{attrs:{"id":"mc-name","placeholder":"Masukkan Tempat Lahir"},model:{value:(_vm.model.name),callback:function ($$v) {_vm.$set(_vm.model, "name", $$v)},expression:"model.name"}}),_c('p',{staticClass:"fs-6 text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Last Update","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Tanggal Lahir","label-for":"mc-date"}},[_c('date-picker',{attrs:{"firstDayOfWeek":1,"placeholder":"Masukkan Tanggal Lahir","name":"date","lang":_vm.lang,"format":_vm.formats,"value-type":"YYYY-MM-DD"},model:{value:(_vm.model.name),callback:function ($$v) {_vm.$set(_vm.model, "name", $$v)},expression:"model.name"}}),_c('p',{staticClass:"fs-6 text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Nama","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"NPWP","label-for":"mc-name"}},[_c('b-form-input',{attrs:{"id":"mc-name","placeholder":"Masukkan NPWP"},model:{value:(_vm.model.name),callback:function ($$v) {_vm.$set(_vm.model, "name", $$v)},expression:"model.name"}}),_c('p',{staticClass:"fs-6 text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Nama","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Kantor Asal","label-for":"mc-name"}},[_c('b-form-input',{attrs:{"id":"mc-name","placeholder":"Masukkan Kantor Asal"},model:{value:(_vm.model.name),callback:function ($$v) {_vm.$set(_vm.model, "name", $$v)},expression:"model.name"}}),_c('p',{staticClass:"fs-6 text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Nama","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Jenis Non Pegawai","label-for":"mc-data-period"}},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.data_periode),expression:"model.data_periode"}],staticClass:"form-control",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.model, "data_periode", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}},[_vm._v("Pilih Jenis Non Pegawai")]),_vm._l((_vm.jenis_nonpegawai),function(i,index){return _c('option',{key:index,domProps:{"value":i.value}},[_vm._v(" "+_vm._s(i.label)+" ")])})],2),_c('p',{staticClass:"fs-6 text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])])]}}],null,true)})],1)],1),_c('b-card-footer',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1 float-right",attrs:{"type":"submit","variant":"primary"}},[_vm._v(" Simpan ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1 float-right",attrs:{"type":"reset","variant":"outline-secondary","to":{ name: 'manajemen-nonpegawai' }}},[_vm._v(" Batal ")])],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }